import { formatWithThousands } from '@sennder/plankton'

export const formatMoneyWithDashForNull = function (number: number) {
  if (number === null) return '-'
  // In case of bugs we show an X to our users so they can easily spot/report it
  if (number === undefined || Number.isNaN(number)) return 'X'

  const sign = number < 0 ? '-' : ''
  const formattedValue = formatWithThousands(Math.abs(number))
  return `${sign}€${formattedValue}`
}
