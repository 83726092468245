























































import { defineComponent, ref, Ref, computed, watch, inject } from '@vue/composition-api'
import { Button, InputField, Form, Chip } from '@sennder/plankton'
import Front from '@frontapp/plugin-sdk'
import useOrderDetails from '@/hooks/useOrderDetailRedirect'
import { OrderOverview } from '@/models/order.model'
import events from '@/notifications/events'
import AnalyticsService, {
  AnalyticsServiceToken
} from '@/services/Analytics/AnalyticsService'

import TextLink from '@/components/atoms/TextLink/TextLink.vue'
import SvgIcon from '@/components/atoms/SvgIcon/SvgIcon.vue'
import MenuSelectorItem from '@/components/atoms/MenuSelectorItem/MenuSelectorItem.vue'
import OrderDetailsInfo from '@/components/organisms/OrderDetailsInfo/OrderDetailsInfo.vue'
import SearchOrdersForm from '@/components/organisms/SearchOrdersForm/SearchOrdersForm.vue'

interface OrderOverviewVm extends OrderOverview {
  matchedReference: string
}

export default defineComponent({
  name: 'OrderDetails',
  components: {
    InputField,
    OrderDetailsInfo,
    TextLink,
    Button,
    Form,
    Chip,
    SvgIcon,
    SearchOrdersForm,
    MenuSelectorItem
  },

  setup(_, { root: { $i18n, $route } }) {
    const analytics = inject<AnalyticsService>(AnalyticsServiceToken)
    const { orders, noResults } = useOrderDetails($route)

    const selectedOrderId: Ref<null | number> = ref(null)

    const selectOrder = (orderId: number) => {
      selectedOrderId.value = orderId
    }

    const selectedOrder = computed(() => {
      let order: OrderOverview | undefined
      if (orders.value) {
        orders.value.some((item) => {
          order = item.orders.find((ord) => ord.id === selectedOrderId.value)
          return !!order
        })
      }
      return order
    })

    const stateLabelByCode = (state: string) => $i18n.t(`status.${state}`)

    const stateLabel = computed(() => {
      if (selectedOrder.value) {
        return stateLabelByCode(selectedOrder.value.state)
      }
      return ''
    })

    const allOrders = computed(() => {
      const mergedArray: OrderOverviewVm[] = []
      if (orders.value) {
        orders.value.forEach((item) => {
          item.orders.forEach((order) =>
            mergedArray.push({ ...order, matchedReference: item.reference })
          )
        })
      }
      return mergedArray
    })

    const stateCategory = computed(() => {
      if (selectedOrder.value) {
        if (
          selectedOrder.value.state === 'REGISTERED' ||
          selectedOrder.value.state === 'DISPATCHED'
        ) {
          return 'primary'
        }
        if (selectedOrder.value.state === 'NEW') {
          return 'secondary'
        }
        if (selectedOrder.value.state === 'CARRIER_LOCKED') {
          return 'red'
        }
        if (
          selectedOrder.value.state === 'EXECUTED' ||
          selectedOrder.value.state === 'OPERATIONS_COMPLETED' ||
          selectedOrder.value.state === 'COMPLETED'
        ) {
          return 'green'
        }
        return 'tertiary'
      }
      return ''
    })

    const onOctopusLinkClick = async (orderId: string) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await (Front as any).openUrl(
        `${process.env.VUE_APP_OCTOPUS_URL}/ordering/${orderId}#/`
      )
      analytics?.trackEvent(events.ORDER_DETAIL_OCTOPUS_LINK_CLICK, {
        orderId: selectedOrderId.value,
        orderIdForStaff: selectedOrder.value?.idForStaff,
        indexInResults: allOrders.value
          ?.map((o) => o.id)
          .indexOf(selectedOrderId.value || -1)
      })
    }

    if (allOrders.value.length === 1) {
      selectedOrderId.value = allOrders.value[0].id
    }

    watch(orders, () => {
      if (allOrders.value.length === 1) {
        selectedOrderId.value = allOrders.value[0].id
      }
    })

    return {
      allOrders,
      selectedOrder,
      noResults,
      stateLabel,
      stateCategory,
      selectOrder,
      stateLabelByCode,
      onOctopusLinkClick
    }
  }
})
