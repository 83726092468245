

































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'GroupedItemList',
  components: {},
  props: {
    title: { type: String, required: true },
    items: { type: Array, required: true },
    nestedGroups: { type: Boolean, default: false }
  },
  setup() {
    return {}
  }
})
