









import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import { OrderOverview } from '@/models/order.model'
import GroupedItemList from '@/components/molecules/GroupedItemList/GroupedItemList.vue'
import { formatMoneyWithDashForNull } from '@/utils/numberFunctions'
import getAlphabetEquivalent from '@/hooks/useStopLetter'
import { humanizeIsoDate, humanizeIsoTime } from '@sennder/plankton'
import i18n from '@/i18n/i18n'

export default defineComponent({
  name: 'OrderDetailsInfo',
  components: { GroupedItemList },
  props: {
    order: { type: Object as PropType<OrderOverview>, required: true }
  },
  setup(props) {
    const { order } = toRefs(props)
    const operationsItems = computed(() => [
      {
        key: i18n.t('orderDetails.seniorAccountManager'),
        value: order.value.accountManager ? order.value.accountManager.email : '-'
      },
      {
        key: i18n.t('orderDetails.accountManager'),
        value: order.value.juniorAccountManager
          ? order.value.juniorAccountManager.email
          : '-'
      },
      {
        key: i18n.t('orderDetails.carrierManager'),
        value: order.value.operator ? order.value.operator.email : '-'
      }
    ])

    const customerItems = computed(() => [
      { key: i18n.t('orderDetails.name'), value: order.value.customer?.name },
      { key: i18n.t('orderDetails.refNo'), value: order.value.referenceNumber },
      {
        key: i18n.t('orderDetails.netPrice'),
        value: formatMoneyWithDashForNull(Number(order.value.totalNetPrice))
      }
    ])
    const carrierItems = computed(() => [
      { key: i18n.t('orderDetails.name'), value: order.value.carrier?.name },
      {
        key: i18n.t('orderDetails.netCost'),
        value: formatMoneyWithDashForNull(Number(order.value.totalNetCost))
      },
      {
        key: i18n.t('orderDetails.vehiculeLicensePlate'),
        value: order.value.transfer?.licensePlate
      },
      {
        key: i18n.t('orderDetails.trailerLicensePlate'),
        value: order.value.transfer?.trailerLicensePlate
      }
    ])
    const routeItems = computed(() =>
      order.value.stopovers.map((stop, index) => ({
        title: `${i18n.t('label.stop')} ${getAlphabetEquivalent(index)} - ${
          stop.stopoverType === 'LOADING'
            ? i18n.t('label.loading')
            : i18n.t('label.unloading')
        }`,
        subItems: [
          {
            key: i18n.t('orderDetails.location'),

            value: `${stop.warehouseAddress.countryCode}, ${stop.warehouseAddress.zipCode}, ${stop.warehouseAddress.city}`
          },
          {
            key: i18n.t('orderDetails.expectedDateAndTime'),
            value: `${humanizeIsoDate(stop.arrivalToWarehouse)} | ${humanizeIsoTime(
              stop.arrivalToWarehouse
            )} - ${humanizeIsoTime(stop.departureFromWarehouse)}`
          },
          {
            key: i18n.t('orderDetails.notes'),
            value: stop.notes
          }
        ]
      }))
    )

    return { operationsItems, customerItems, carrierItems, routeItems }
  }
})
