







import { defineComponent } from '@vue/composition-api'
import SvgIcon from '@/components/atoms/SvgIcon/SvgIcon.vue'

export default defineComponent({
  name: 'MenuSelectorItem',
  components: { SvgIcon },
  props: {},
  setup() {
    return {}
  }
})
